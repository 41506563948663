import React, { Component } from "react";
import { Card, Col, Row, Skeleton } from "antd";

export default class CompanyDetail extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    const titleStyle = {
      fontSize: "small",
      fontWeight: 500,
    };
    const valueStyle = {
      fontWeight: 400,
    };
    const rowStyle = {
      marginBottom: 7.5,
    };

    return (
      <Card title={this.props.Name} bordered={false}>
        <Skeleton loading={false}>
          <Row gutter={24} style={rowStyle}>
            <Col span={10} style={titleStyle}>
              Description:
            </Col>
            <Col span={14} style={valueStyle}>
              {this.props.Description}
            </Col>
          </Row>
          {/* <Row gutter={24} style={rowStyle}> */}
          {/*    <Col span={10} style={titleStyle}> */}
          {/*        Admin Username: */}
          {/*    </Col> */}
          {/*    <Col span={14} style={valueStyle}> */}
          {/*        {this.props.AdminUsername} */}
          {/*    </Col> */}
          {/* </Row> */}
          <Row gutter={24} style={rowStyle}>
            <Col span={10} style={titleStyle}>
              Phone Number:
            </Col>
            <Col span={14} style={valueStyle}>
              {this.props.PhoneNumber}
            </Col>
          </Row>
          <Row gutter={24} style={rowStyle}>
            <Col span={10} style={titleStyle}>
              Email:
            </Col>
            <Col span={14} style={valueStyle}>
              {this.props.Email}
            </Col>
          </Row>
          <Row gutter={24} style={rowStyle}>
            <Col span={10} style={titleStyle}>
              Opening Time:
            </Col>
            <Col span={14} style={valueStyle}>
              {this.props.OpeningTime.toLocaleTimeString()}
            </Col>
          </Row>
          <Row gutter={24} style={rowStyle}>
            <Col span={10} style={titleStyle}>
              Closing Time:
            </Col>
            <Col span={14} style={valueStyle}>
              {this.props.ClosingTime.toLocaleTimeString()}
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  }
}
