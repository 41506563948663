import React from "react";
import { enquireScreen } from "enquire-js";

import Banner5 from "./Banner5";
import Nav3 from "./Nav3";
import Feature7 from "./Feature7";
import Feature0 from "./Feature0";
import Footer1 from "./Footer1";

import {
  Banner50DataSource,
  Nav30DataSource,
  Feature70DataSource,
  Feature00DataSource,
  Footer10DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";
import SignUpButtons from "./SignUpButtons";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  render() {
    const children = [
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Nav3
        id="Nav3_0"
        key="Nav3_0"
        dataSource={Nav30DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Feature6
      //   id="Feature6_0"
      //   key="Feature6_0"
      //   dataSource={Feature60DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <SignUpButtons key="sign-up-buttons" />,
      <Feature7
        id="Feature7_0"
        key="Feature7_0"
        dataSource={Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />,

      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.show && children}
      </div>
    );
  }
}
