import React, { useEffect, useState } from "react";
import { Layout, Menu, message, Spin } from "antd";
import {
  EditOutlined,
  HistoryOutlined,
  LogoutOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../images/logo.png";

import "../css/user-homepage.css";
import UserDashboard from "./UserDashboard";
import UserSessionsPage from "./UserSessionsPage";
import UserEditProfile from "./UserEditProfile";
import UserSiteVisitsPage from "./UserSiteVisitsPage";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

import { enquireScreen } from "enquire-js";

export default function UserHomepage() {
  const history = useHistory();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const [selectedKey, setSelectedKey] = useState("");
  const [siteIds, setSiteIds] = useState([]);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);

  const dashboard = <UserDashboard />;

  const userSiteVisitsPage = <UserSiteVisitsPage />;

  // const passesPage = <UserPassesPage />;

  const historyPage = <UserSessionsPage />;

  const editProfilePage = <UserEditProfile />;

  const logout = () => {
    Cookies.remove("site_id");
    Cookies.remove("scanner_id");
    Cookies.remove("user_id");
    Cookies.remove("as");
    history.push("/visitor/login");
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const getSites = async () => {
    setSites([]);
    for (let i = 0; i < siteIds.length; i++) {
      let url = `${process.env.REACT_APP_API_URL}/sites/${siteIds[i]}`;
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            setLoading(false);
            let tempSites = sites.concat(res);
            setSites(tempSites);
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          message.error(e.message);
        });
    }
  };

  const user_id = Cookies.get("user_id");

  const getMenus = () => {
    setMenus(
      sites.map((site) => (
        <Menu.Item key={`/visitor/sites/${site.id}`} icon={<HistoryOutlined />}>
          {site.name}
          <Link to={`/visitor/sites/${site.id}`} />
        </Menu.Item>
      ))
    );
  };

  const getUserSites = () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/users/${user_id}/sites`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          setLoading(false);
          setSiteIds([...res]);
          return Promise.resolve("");
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e.message);
      });
  };

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname, selectedKey]);

  useEffect(getUserSites, []);

  useEffect(() => {
    getSites().then(() => {});
  }, [siteIds]);

  useEffect(getMenus, [sites]);

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile({ isMobile: !!b });
    });
  }, []);

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="md"
        theme="light"
        style={{ paddingTop: 20 }}
      >
        <Link to="/visitor">
          <img
            className="logo"
            style={{
              height: isMobile ? "30px" : " 50px",
              textAlign: isMobile ? "center" : "left",
              marginLeft: "0",
              opacity: " 1",
            }}
            src={logo}
          />
        </Link>
        <Menu
          mode="inline"
          style={{}}
          selectedKeys={selectedKey}
          multiple={false}
        >
          <Menu.Item key="/visitor/key" icon={<QrcodeOutlined />}>
            Key
            <Link to="/visitor/key" />
          </Menu.Item>
          {/* <Menu.Item key="passes" icon={<CreditCardOutlined />}>
            Passes
            <Link to="/visitor/passes" />
          </Menu.Item> */}
          <Menu.Item key="/visitor/history" icon={<HistoryOutlined />}>
            History
            <Link to="/visitor/history" />
          </Menu.Item>
          {menus.length > 0 ? (
            <SubMenu key="sub2" icon={<InfoCircleOutlined />} title="Sites">
              {loading ? (
                <Menu.Item disabled={true} key="spinner_menu">
                  <Spin spinning={loading} />
                </Menu.Item>
              ) : (
                menus
              )}
            </SubMenu>
          ) : (
            <div />
          )}
          <SubMenu key="sub1" icon={<SettingOutlined />} title="Account">
            <Menu.Item key="/visitor/edit" icon={<EditOutlined />}>
              Edit Profile
              <Link to="/visitor/edit" />
            </Menu.Item>
            <Menu.Item key="log-out" icon={<LogoutOutlined />} onClick={logout}>
              Log out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="user-layout" style={{ background: "#fff" }}>
        <Content className="user-layout-background">
          <div
            className="user-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              <Route path="/visitor/history" exact render={() => historyPage} />
              <Route path="/visitor/edit" render={() => editProfilePage} />
              <Route path="/visitor/key" render={() => dashboard} />
              <Route
                path="/visitor/sites/:siteId"
                render={() => userSiteVisitsPage}
              />
              <Route
                path="/visitor/"
                render={() => <Redirect to="/visitor/key" />}
              />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
