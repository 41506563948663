import React from "react";

import NavbarUnauth from "./NavbarUnauth";

import "../css/auth.css";
import UserLoginForm from "./UserLoginForm";
import UserRegistrationForm from "./UserRegistrationForm";
import { Route } from "react-router-dom";

export default function VisitorAuth() {
  return (
    <div className="content">
      <NavbarUnauth color="#2A71C3" />
      <div className="container">
        <Route path="/visitor/login" component={UserLoginForm} />
        <Route path="/visitor/register" component={UserRegistrationForm} />
      </div>
    </div>
  );
}
