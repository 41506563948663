const PhoneValidator = () => ({
  validator(rule, value) {
    if (!isNaN(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid phone number.");
  },
});

export default PhoneValidator;
