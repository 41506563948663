import React, { useState } from "react";
import { Button, Card, Form, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { prefixSelector } from "./PhoneNumberInput";
import PhoneValidator from "../functions/PhoneValidator";

export default function UserLoginForm() {
  const [isLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/visitor/" } };

  const submit = (values) => {
    setIsLoading(true);
    const url = `  ${process.env.REACT_APP_API_URL}/users/login`;
    const data = {
      phone: `${values.prefix}${values.phone}`,
      password: values.password,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          setIsError(true);
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        Cookies.set("user_id", res.id, { expires: 365 });
        Cookies.set("token", res.token, { expires: 365 });
        Cookies.set("as", "user", { expires: 365 });
      })
      .catch((e) => {
        setIsLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        history.push(from);
      });
  };
  const regStyle = {
    marginTop: "10px",
  };

  if (isLoggedIn) {
    history.push(from);
  }
  return (
    <Card className="login-card" style={{ textAlign: "center" }}>
      <Form name="normal-login" onFinish={submit}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input the phone number you registered with.",
            },
            PhoneValidator(),
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{ width: "100%" }}
            placeholder="Phone number"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password." }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        {isError && (
          <p style={{ color: "red" }}>
            The phone number or password provided were incorrect!
          </p>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            shape="round"
            style={{ marginTop: 10, marginBottom: 10 }}
            loading={isLoading}
          >
            Log in
          </Button>
          <p style={regStyle}>Don't have an account?</p>
          <Link to="/visitor/register">
            <Button type="link" size="small" style={{ padding: 0, margin: 0 }}>
              Register now!
            </Button>
          </Link>
        </Form.Item>
      </Form>
    </Card>
  );
}
