import React, { useEffect, useState } from "react";
import { PageHeader } from "antd";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { enquireScreen } from "enquire-js";

export default function NavbarUnauth(props) {
  const [isMobile, setIsMobile] = useState();
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile({ isMobile: !!b });
    });
  }, []);
  return (
    <PageHeader
      ghost
      title={
        <Link to="/">
          <img
            className="logo"
            style={{
              height: isMobile ? "30px" : " 50px",
              margin: " 5px !important",
              opacity: " 1",
            }}
            src={logo}
          />
        </Link>
      }
    />
  );
}
