import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Drawer,
  Input,
  List,
  message,
  Skeleton,
  Space,
  Spin,
  Table,
} from "antd";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";

import "../css/user-site-visits.css";
import Modal from "antd/lib/modal/Modal";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import SiteScannerDetail from "./SiteScannerDetail";
import { getDrawerPlacement } from "../functions/ResponsiveVariables";
import CompanyDetail from "./CompanyDetail";

export default function UserSiteUnregisteredVisitsPage() {
  const { siteId } = useParams();

  const [initLoading, setInitLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [visitSession, setVisitSession] = useState({});
  const [list, setList] = useState([]);
  const [startScannerUsername, setStartScannerUsername] = useState("");
  const [endScannerUsername, setEndScannerUsername] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [scannerDetailModalVisible, setScannerDetailModalVisible] = useState(
    false
  );
  const [companyDetailModalVisible, setCompanyDetailModalVisible] = useState(
    false
  );
  const [scannerInfo, setScannerInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const showCompanyDetails = (company) => {
    setCompanyInfo(company);
    setCompanyDetailModalVisible(true);
  };

  const getScannerName = (id, setter) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${siteId}/scanners?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setter(res.username);
      })
      .catch((e) => {
        return e.message;
      });
  };

  const showScannerDetails = (scannerUsername) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${siteId}/scanners?username=${scannerUsername}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        fetch(`${process.env.REACT_APP_API_URL}/users/${res.user_id}`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        })
          .then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }
            return res.json();
          })
          .then((res) => {
            setScannerDetailModalVisible(true);
            setScannerInfo(res);
          })
          .catch((e) => {
            setScannerInfo({});
            setScannerDetailModalVisible(false);
            message.error(e.message);
          });
      })
      .catch((e) => {
        return e.message;
      });
  };

  const onClose = () => {
    setVisitSession({});
    setDrawerVisible(false);
  };

  const hideScannerDetailModal = () => {
    setScannerDetailModalVisible(false);
  };
  const hideCompanyDetailModal = () => {
    setCompanyDetailModalVisible(false);
  };

  useEffect(() => {
    const id = Cookies.get("user_id");
    const getData = () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/${id}/sites/${siteId}/unregistered-user-visits`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error("");
        })
        .then((res) => {
          if (res != null) {
            let modifiedRes = res.map((session) => ({
              ...session,
              key: session.id,
            }));
            setInitLoading(false);
            setList(modifiedRes);
          } else {
            setInitLoading(false);
          }
        })
        .catch((e) => {
          message.error(e.message);
          setInitLoading(false);
        });
    };
    getData();
  }, [siteId]);

  let searchInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : dataIndex === "firstName"
        ? record["name"] !== undefined
          ? record["name"]["first"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "lastName"
        ? record["name"] !== undefined
          ? record["name"]["last"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "middleName"
        ? record["name"] !== undefined
          ? record["name"]["middle"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : text !== undefined && text !== "" && text !== null ? (
        text
      ) : (
        "-"
      ),
  });

  const columns = [
    {
      title: "First Name",
      dataIndex: ["name", "first"],
      key: "firstName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.first.localeCompare(b.name.first);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Middle Name",
      dataIndex: ["name", "middle"],
      key: "middleName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.middle.localeCompare(b.name.middle);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("middleName"),

      responsive: ["lg"],
    },
    {
      title: "Last Name",
      dataIndex: ["name", "last"],
      key: "lastName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.last.localeCompare(b.name.last);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Entered At",
      dataIndex: "start_time",
      key: "start_time",
      sorter: (a, b) => {
        return new Date(a.start_time) > new Date(b.start_time);
      },
      width: "20%",
      ...getColumnSearchProps("start_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Exited At",
      dataIndex: "end_time",
      key: "end_time",
      width: "20%",
      sorter: (a, b) => {
        return new Date(a.end_time) > new Date(b.end_time);
      },
      ...getColumnSearchProps("end_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id) => {
        return (
          <Button
            type="link"
            onClick={() => {
              let item = list.find((item) => item.id === id);
              if (item) {
                setDrawerVisible(true);
                setVisitSession(item);
                setUserLoading(true);
                getScannerName(item.start_scanner_id, setStartScannerUsername);
                if (
                  item.end_scanner_id !== "system" &&
                  item.end_scanner_id !== undefined
                ) {
                  getScannerName(item.end_scanner_id, setEndScannerUsername);
                } else if (item.end_scanner_id !== undefined) {
                  setEndScannerUsername("System");
                } else {
                  setEndScannerUsername("Still in the premises");
                }
                setUserLoading(false);
              }
            }}
          >
            <InfoCircleOutlined />
          </Button>
        );
      },
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <>
      <Spin spinning={initLoading} className="visits-container">
        <Table
          columns={columns}
          dataSource={list}
          style={{ overflowX: "scroll", margin: "0" }}
        />
      </Spin>
      <Drawer
        placement={getDrawerPlacement()}
        height="75vh"
        width="35%"
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
      >
        <Card
          title={
            visitSession.name
              ? `${visitSession.name.first} ${
                  visitSession.name.middle != null
                    ? visitSession.name.middle + " "
                    : ""
                }${visitSession.name.last}`
              : ""
          }
          bordered={false}
        >
          <Skeleton loading={userLoading} paragraph>
            <p>{`Phone Number: ${visitSession.phone_number ?? "-"}`}</p>
            <p>{`ID Number: ${visitSession.id_number ?? "-"}`}</p>
            <p>{`Email: ${visitSession.email ?? "-"}`}</p>
            <p>{`Company: ${visitSession.company ?? "-"}`}</p>
            <p>{`Car License Plate: ${
              visitSession.car_license_plate ?? "-"
            }`}</p>

            <p>
              Entrance Scanner:{" "}
              <Button
                type="link"
                size="small"
                style={{ padding: 0, margin: 0 }}
                onClick={() => {
                  showScannerDetails(startScannerUsername);
                }}
              >
                {startScannerUsername}
              </Button>
            </p>
            <p>
              Company Visited:{" "}
              <Button
                type="link"
                onClick={() => {
                  if (visitSession.company !== undefined) {
                    showCompanyDetails(visitSession.company);
                  }
                }}
              >
                {` ${
                  visitSession.company !== undefined
                    ? visitSession.company.name
                    : "-"
                }`}
              </Button>
            </p>
            <p>{`Entrance Time: ${
              visitSession.start_time !== undefined
                ? new Date(visitSession.start_time).toLocaleString()
                : "-"
            }`}</p>

            <p>Entrance Scanner's Note: {visitSession.start_note ?? "-"}</p>
            <p>{`Entrance Time: ${
              visitSession.start_time !== undefined
                ? new Date(visitSession.start_time).toLocaleString()
                : "-"
            }`}</p>
            <p>Flagged: {visitSession.flagged ? "Yes" : "No"}</p>

            {visitSession.extras !== null ? (
              <List
                dataSource={visitSession.extras}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.name}
                      description={item.value}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <div />
            )}
            <p>
              Exit Scanner:{" "}
              <Button
                type="link"
                size="small"
                style={{ padding: 0, margin: 0 }}
                onClick={() => {
                  if (
                    endScannerUsername !== "System" &&
                    !String(endScannerUsername).includes(" ")
                  )
                    showScannerDetails(endScannerUsername);
                }}
              >
                {endScannerUsername}
              </Button>
            </p>
            <p>Exit Scanner's Note: {visitSession.end_note ?? "-"}</p>
            <p>{`Exit Time: ${
              visitSession.end_time !== undefined
                ? new Date(visitSession.end_time).toLocaleString()
                : "-"
            }`}</p>
            <p style={{ textAlign: "center" }} />
          </Skeleton>
        </Card>
      </Drawer>
      <Modal
        visible={scannerDetailModalVisible}
        footer={null}
        onCancel={hideScannerDetailModal}
      >
        <SiteScannerDetail
          footer={null}
          fullName={
            scannerInfo.name
              ? `${scannerInfo.name.first} ${scannerInfo.name.middle} ${scannerInfo.name.last}`
              : "-"
          }
          phone={scannerInfo.phone_number ? scannerInfo.phone_number : "-"}
          email={scannerInfo.email ? scannerInfo.email : "-"}
        />
      </Modal>
      <Modal
        visible={companyDetailModalVisible}
        footer={null}
        onCancel={hideCompanyDetailModal}
      >
        <CompanyDetail
          Name={companyInfo.name}
          Description={companyInfo.description}
          AdminUsername={companyInfo.admin_username}
          PhoneNumber={
            companyInfo.phone_numbers !== undefined
              ? companyInfo.phone_numbers[0]
              : "-"
          }
          Email={companyInfo.email}
          OpeningTime={new Date(companyInfo.opening_time) || "-"}
          ClosingTime={new Date(companyInfo.closing_time) || "-"}
        />
      </Modal>
    </>
  );
}
