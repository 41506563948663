import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";

function PrivateVisitorRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }, props) =>
        Cookies.get("user_id") !== undefined && Cookies.get("as") === "user" ? (
          Component ? (
            <Component {...props} />
          ) : (
            render()
          )
        ) : (
          <Redirect
            to={{
              pathname: "/visitor/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateVisitorRoute;
