import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import * as serviceWorker from "./serviceWorker";
// import SiteHomepage from "./components/SiteHomepage";
import App from "./App";
// import { BrowserRouter, Route } from "react-router-dom";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
