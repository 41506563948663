import React, { useEffect, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Spin,
  Tooltip,
} from "antd";
import { prefixSelector } from "./PhoneNumberInput";
import moment from "moment";

import "../css/user-edit.css";
import {
  EyeInvisibleOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PhoneValidator from "../functions/PhoneValidator";
import ChangePassword from "./ChangePassword";
import PhoneFormatter from "../functions/PhoneFormatter";

export default function UserEditProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  const formRef = React.createRef();
  let history = useHistory();

  const onFinish = (values) => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/users/${Cookies.get(
      "user_id"
    )}`;
    let data = {
      name: {
        first: values["fname"],
        middle: values["mname"],
        last: values["lname"],
      },
      phone_number: PhoneFormatter(values["phone"]),
      email: values["email"],
      id_number: values["id_number"],
      company: values["company"],
      dob: values["dob"],
      car_license_plate: values["clp"],
    };
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then(() => {
        message.success("Successfully updated!");
        history.push("/visitor/");
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  useEffect(() => {
    const getUser = () => {
      let url = `${process.env.REACT_APP_API_URL}/users?id=${Cookies.get(
        "user_id"
      )}`;
      setTimeout(10000);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            setIsLoadingProfile(false);
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then((res) => {
          if (formRef.current !== null) {
            formRef.current.setFieldsValue({
              fname: res.name.first,
              mname: res.name.middle,
              lname: res.name.last,
              email: res.email,
              prefix: res.phone_number.slice(0, 4),
              phone: res.phone_number.slice(4),
              id_number: res.id_number,
              company: res.company,
              clp: res.car_license_plate,
              dob: new moment(res.dob),
            });
          }
          setIsLoadingProfile(false);
        })
        .catch((e) => {
          message.error(e.message);
          setIsLoadingProfile(false);
        });
    };
    getUser();
  }, [formRef]);

  return (
    <div className="user-editor-container">
      <Spin spinning={isLoadingProfile} indicator={<LoadingOutlined />}>
        <Avatar
          icon={<UserOutlined style={{ fontSize: 50 }} />}
          className="avatar"
        />
      </Spin>

      <Form
        name="edit-user-profile"
        onFinish={onFinish}
        scrollToFirstError
        className="user-editor"
        ref={formRef}
      >
        <Spin spinning={isLoadingProfile} indicator={<LoadingOutlined />}>
          <Tooltip title="Enter your first name as it appears on your ID card.">
            <Form.Item
              name="fname"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name.",
                },
              ]}
            >
              <Input placeholder="First name" />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Enter your middle name as appears on your ID card.">
            <Form.Item name="mname">
              <Input placeholder="Middle name (optional)" />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter your last name as it appears on your ID card.">
            <Form.Item
              name="lname"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name.",
                },
              ]}
            >
              <Input placeholder="Last name" />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter your email address.">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid email.",
                },
              ]}
            >
              <Input placeholder="Email (optional)" />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter a phone number you can later use to log in.">
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input a phone number.",
                },
                PhoneValidator(),
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
                placeholder="Phone number"
              />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter your government given ID Number.">
            <Form.Item
              name="id_number"
              rules={[
                {
                  required: true,
                  message: "Please input ID Number.",
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="ID Number" />
            </Form.Item>
          </Tooltip>
          <Form.Item>
            <Link to="/visitor/edit/password">
              <Button style={{ width: "100%" }} icon={<EyeInvisibleOutlined />}>
                Change Password
              </Button>
            </Link>
          </Form.Item>
          <Tooltip title="Enter your date of birth as it appears on your ID card.">
            <Form.Item
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please enter your date of birth.",
                },
              ]}
            >
              <DatePicker
                placeholder="Date of birth"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter the name of the company you work for or represent.">
            <Form.Item name="company">
              <Input placeholder="Company (optional)" />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Enter your car's license plate number.">
            <Form.Item name="clp">
              <Input placeholder="Car license plate number (optional)" />
            </Form.Item>
          </Tooltip>
          {/* <Form.Item>
            <Link to="visitor/edit/password">
              <Button style={{ width: "100%" }}>Change Password</Button>
            </Link>
          </Form.Item> */}
          <Form.Item
            style={{
              textAlign: "center",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ flexGrow: 1 }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Spin>
      </Form>

      <Route
        path="/visitor/edit/password"
        exact
        render={() => (
          <ChangePassword
            id={Cookies.get("user_id")}
            urlPath={`${process.env.REACT_APP_API_URL}/users/${Cookies.get(
              "user_id"
            )}/password`}
            source="/visitor/edit"
          />
        )}
      />
    </div>
  );
}
