import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import Cookies from "js-cookie";

export default function ChangePassword(props) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const close = () => {
    history.push(props.source);
  };

  const changePassword = (values) => {
    setLoading(true);
    const body = {
      id: props.id,
      old_password: values.old_password,
      new_password: values.new_password,
    };
    fetch(props.urlPath, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Some error from server: ${res.statusText} `);
        }
        message.success("Password changed successfuly!");
        close();
      })
      .catch((e) => {
        message.error(e.message);
        setLoading(false);
      });
  };

  return (
    <Modal visible footer={null} onCancel={close} title="Change Password">
      <Form style={{ margin: "50px" }} onFinish={changePassword}>
        <Form.Item name="old_password">
          <Input.Password placeholder="Old Password" />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Please enter your old password." },
            { min: 8, message: "Password should be longer than 8 characters." },
          ]}
          name="new_password"
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please confirm your password.",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match."
                );
              },
            }),
          ]}
          name="confirm_new_password"
        >
          <Input.Password placeholder="Confirm New Password" />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Change
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
