import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

export default function SiteScannerDetail({ entry, exit, ...props }) {
  return (
    <Card bordered={false} title={props.fullName}>
      <p>{`Phone Number: ${props.phone}`}</p>
      <p>{`Email: ${props.email}`}</p>
    </Card>
  );
}

SiteScannerDetail.propTypes = {
  entry: PropTypes.bool,
  exit: PropTypes.bool,
};
