import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { message } from "antd";
import QrScanner from "./QrScanner";

const user_id = Cookies.get("user_id");

function UserQrModal({ history }) {
  const [codeLoading, setCodeLoading] = useState(false);
  const [result, setResult] = useState("");

  function hideQrModal() {
    setCodeLoading("");
    history.push("/visitor/passes");
  }

  function handleScan(data) {
    if (data != null && data !== result) {
      setCodeLoading(true);
      setResult(data);
      fetch(
        `${process.env.REACT_APP_API_URL}/passes/users/${user_id}/${data}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then(async (response) => {
          if (response.status === 200) {
            setCodeLoading(false);
            return response.json();
          }
          setCodeLoading(false);
          message.error("Site not found");
          throw new Error("Site not found");
        })
        .then((data) => {
          hideQrModal();
        })
        .catch((e) => {
          message.error(e.message);
          setCodeLoading(false);
        });
    }
  }

  function handleError(err) {
    message.error(err.message);
  }

  return (
    <Modal
      visible
      closable
      onCancel={() => {
        hideQrModal();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
      footer={null}
    >
      <QrScanner
        loading={codeLoading}
        onScan={handleScan}
        onError={handleError}
      />
      {/* <Spin spinning={codeLoading}>
        <QrReader
          style={{
            height: 240,
            width: 240,
          }}
          onScan={(data) => handleScan(data)}
          onError={handleError}
          showViewFinder={false}
          facingMode={"rear"}
        />
        <div className="ocrloader">
          <em></em>
          <span></span>
        </div>

        <p style={{ color: "red" }}>{codeLoadingError}</p>
      </Spin> */}
    </Modal>
  );
}

export default withRouter(UserQrModal);
