import React, { Component } from "react";

import { Button, Card, Drawer, Modal, Spin, Table, Input, Space } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";

import "../css/user-sessions.css";
import { getDrawerPlacement } from "../functions/ResponsiveVariables";
import CompanyDetail from "./CompanyDetail";

export default class UserSessionsPage extends Component {
  state = {
    company: {},
    companyModalVisible: false,
    drawerVisible: false,
    initLoading: true,
    list: [],
    searchedColumn: "",
    searchText: "",
    visitSession: {},
  };

  showCompanyDetails = (company) => {
    this.setState({
      company,
      companyDetailModalVisible: true,
    });
  };

  hideCompanyDetailModal = () => {
    this.setState({
      companyDetailModalVisible: false,
    });
  };

  getData = async () => {
    fetch(
      `  ${process.env.REACT_APP_API_URL}/users/${Cookies.get(
        "user_id"
      )}/visits`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          let modifiedRes = res.map((session) => ({
            ...session,
            key: session.id,
          }));
          this.setState({
            initLoading: false,
            list: modifiedRes,
          });
        } else {
          this.setState({
            initLoading: false,
          });
        }
      });
  };

  showDrawer = (item) => {
    this.setState({
      visitSession: item,
      drawerVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  componentDidMount() {
    this.getData();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : dataIndex === "name"
        ? record["site"] !== undefined
          ? record["site"]["name"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : text !== undefined && text !== "" && text !== null ? (
        text
      ) : (
        "-"
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: ["site", "name"],
        key: "name",
        width: "30%",
        sorter: (a, b) => {
          if (a.site !== undefined) {
            if (b.site !== undefined) {
              return a.site.name.localeCompare(b.site.name);
            }
            return true;
          }
          return b.site !== undefined;
        },
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Entered At",
        dataIndex: "start_time",
        key: "start_time",
        sorter: (a, b) => {
          return new Date(a.start_time) > new Date(b.start_time);
        },
        width: "20%",
        ...this.getColumnSearchProps("start_time"),
        render: (time) => {
          if (time !== undefined) {
            let d = new Date(time);
            return d.toLocaleString();
          } else {
            return "-";
          }
        },
      },
      {
        title: "Exited At",
        dataIndex: "end_time",
        key: "end_time",
        width: "20%",
        sorter: (a, b) => {
          return new Date(a.end_time) > new Date(b.end_time);
        },
        ...this.getColumnSearchProps("end_time"),
        render: (time) => {
          if (time !== undefined) {
            let d = new Date(time);
            return d.toLocaleString();
          } else {
            return "-";
          }
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        width: "20%",
        render: (id) => {
          return (
            <Button
              type="link"
              onClick={() =>
                this.showDrawer(this.state.list.find((item) => item.id === id))
              }
              icon={<InfoCircleOutlined />}
            ></Button>
          );
        },
      },
    ];

    return (
      <>
        <Spin className="sessions-container" spinning={this.state.initLoading}>
          <Table
            columns={columns}
            dataSource={this.state.list}
            style={{ overflowX: "scroll", margin: "0" }}
          />
        </Spin>
        <Drawer
          className="sessions-drawer"
          placement={getDrawerPlacement()}
          height="55vh"
          width="35%"
          closable
          onClose={this.onClose}
          visible={this.state.drawerVisible}
        >
          <Card
            title={
              this.state.visitSession.site
                ? this.state.visitSession.site.name
                : ""
            }
            bordered={false}
          >
            {this.state.visitSession.site !== undefined ? (
              <div style={{ textAlign: "left" }}>
                <p style={{ textAlign: "center" }}>
                  {this.state.visitSession.site.description}
                </p>
                <p>
                  <b>Email</b> {this.state.visitSession.site.email}
                </p>
                <p>
                  <b>Phone</b> {this.state.visitSession.site.phone_numbers[0]}
                </p>
                <p>
                  Company Visited:{" "}
                  <Button
                    type="link"
                    onClick={() => {
                      if (this.state.visitSession.company !== undefined) {
                        this.showCompanyDetails(
                          this.state.visitSession.company
                        );
                      }
                    }}
                  >
                    {` ${
                      this.state.visitSession.company !== undefined
                        ? this.state.visitSession.company.name
                        : "-"
                    }`}
                  </Button>
                </p>
                <p>
                  <b>Entered at</b>{" "}
                  {new Date(
                    this.state.visitSession.start_time
                  ).toLocaleString()}
                </p>
                <p>
                  <b>Exited at</b>{" "}
                  {this.state.visitSession.end_time
                    ? new Date(
                        this.state.visitSession.end_time
                      ).toLocaleString()
                    : "Still in the premises."}
                </p>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Drawer>
        <Modal
          visible={this.state.companyDetailModalVisible}
          footer={null}
          onCancel={this.hideCompanyDetailModal}
        >
          <CompanyDetail
            Name={this.state.company.name}
            Description={this.state.company.description}
            PhoneNumber={
              this.state.company.phone_numbers !== undefined
                ? this.state.company.phone_numbers[0]
                : "-"
            }
            Email={this.state.company.email}
            OpeningTime={new Date(this.state.company.opening_time) || "-"}
            ClosingTime={new Date(this.state.company.closing_time) || "-"}
          />
        </Modal>
      </>
    );
  }
}
