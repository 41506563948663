import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function PublicRoute({ component: Component, render, ...rest }) {
  const location = useLocation();
  if (String(location.pathname).slice(1, 8).toLowerCase() === "visitor") {
    return (
      <Route
        {...rest}
        render={({ location }, props) =>
          Cookies.get("user_id") !== undefined &&
          Cookies.get("as") === "user" ? (
            <Redirect
              to={{
                pathname: "/visitor/key",
                state: { from: location },
              }}
            />
          ) : Component ? (
            <Component {...props} />
          ) : (
            render()
          )
        }
      />
    );
  }
  return <Redirect to="/" />;
}
export default PublicRoute;
