import React, { useState } from "react";
import { Button, Card, Spin } from "antd";
import { RiCameraSwitchLine } from "react-icons/ri";
import QrReader from "react-qr-scanner";

export default function QrScanner(props) {
  const [cameraMode, setCameraMode] = useState("rear");

  const toggleCameraMode = () => {
    if (cameraMode === "rear") {
      setCameraMode("front");
    } else {
      setCameraMode("rear");
    }
  };
  return (
    <Card bordered={false}>
      <div style={{ textAlign: "right" }}>
        <Button
          icon={<RiCameraSwitchLine size="18" />}
          style={{ margin: "0" }}
          onClick={toggleCameraMode}
        />
      </div>
      <Spin spinning={props.loading}>
        <QrReader
          style={{
            height: 240,
            width: 240,
          }}
          onScan={(data) => props.onScan(data)}
          onError={props.onError}
          showViewFinder={false}
          facingMode={cameraMode}
        />
        <div className="ocrloader">
          <em />
          <span />
        </div>
        {/* <QrReader
          legacyMode={true}
          onScan={(data) => props.onScan(data)}
          onError={props.onError}
        /> */}
      </Spin>
    </Card>
  );
}
