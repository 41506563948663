import { Button, Col, Divider, Row, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function SignUpButtons(props) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F7F9FC",
        padding: "3vh",
        paddingTop: "7vh",
      }}
      id="get-started"
    >
      <h1 style={{ textAlign: "center", fontSize: "30px" }}>Continue as</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "10vh",
          width: "100%",
          padding: "3vh",
        }}
      >
        <Button
          shape="round"
          style={{ width: "30%", maxWidth: "120px", height: "40px" }}
          type="primary"
        >
          <Link to="/visitor/login">Visitor</Link>
        </Button>

        <div style={{ width: "10%" }}></div>

        <Button
          shape="round"
          style={{ width: "30%", maxWidth: "120px", height: "40px" }}
          type="primary"
        >
          <a href="https://qribu-site.netlify.app">Site</a>
        </Button>
      </div>
      <Divider />
    </div>
  );
}
