import React from "react";
import BigMobileImage from "../images/mobile.svg";
import Exit from "../images/exit.svg";
import GenerateAccessCode from "../images/generate.svg";
import Questions from "../images/questions.svg";
import ShareCode from "../images/share_code.svg";
import {
  RiVirusFill,
  RiTimerFlashFill,
  RiFileList3Fill,
  RiQuestionnaireFill,
  RiBuildingFill,
  RiAdminFill,
} from "react-icons/ri";
import bigLogo from "../images/logo.png";

class Logo extends React.Component {
  render() {
    return <img src={bigLogo} style={{ height: "155px" }} />;
  }
}
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      {
        name: "logo",
        children: Logo,
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: "Your #1 choice for logging visitor entries.",
      },
      {
        name: "content",
        className: "banner5-content",
        children:
          "Even better, the process is 100% contactless, we all know how important that is due to the health risks we are in.",
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "#get-started",
          className: "banner5-button",
          type: "primary",
          children: "Get started",
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children: BigMobileImage,
  },
};
export const Nav30DataSource = {
  wrapper: { className: "header3 home-page-wrapper nav30-header" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo nav30-logo",
    // children: Logo,
  },
  Menu: {
    className: "header3-menu",
    children: [
      {
        name: "item1",
        className: "header3-item",
        children: {
          href: "#Feature0_0",
          children: [{ children: <p>How does it work?</p>, name: "text" }],
        },
      },
      {
        name: "item2",
        className: "header3-item",
        children: {
          href: "#get-started",
          children: [{ children: <p>Log in</p>, name: "text" }],
        },
      },
    ],
  },
  mobileMenu: { className: "header3-mobile-menu" },
};
export const Feature70DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: "Features",
      },
      {
        name: "content",
        className: "feature7-title-content",
        children: "These are the features available for sites.",
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: "block0",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiVirusFill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Safety",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "Entry information is obtained from visitors in a fast, non-contact method. This ensures the safety of both your visitors and security personnel.",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block1",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiTimerFlashFill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Availability of Records",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "You can access visit logs anytime using our portal any time from any device.",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block2",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiFileList3Fill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Organized Visit Logs",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "You can easily find logs of visitor and see details about their visit. Unlike traditional logs by the book, where you will have to go through lots of pages, all you have to do is search and filter.",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block3",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiQuestionnaireFill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Ask Extra Questions",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "You can opt in to add extra questions for visitors to answer before they enter your site. This can be used to identify the intent of the visit.",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block4",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiBuildingFill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Add Tenants to Your Site",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "If your site has more than one company/resident, you can add them. This will give them access visit logs to their company/residency.",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block5",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "logo",
              className: "feature7-block-image",
              children: RiAdminFill,
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "Give Access to Logs",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children:
                "You can give viewing access of your logs to multiple users. This will help you share information with your staff.",
            },
          ],
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "How It Works" }],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "jzjn8afnsxb-editor_css content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item jzjgrrupf2c-editor_css",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjgrlz134-editor_css",
              children: GenerateAccessCode,
            },
            {
              name: "title",
              className: "content0-block-title jzj8xt5kgv7-editor_css",
              children: "Generate Access Code",
            },
            {
              name: "content",
              children:
                "The visitor logs into their Qribu account and generates a one-time-use 4 digit code. This is randomly generated for a user and can only work once.",
              className: "jzj8z9sya9-editor_css",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjncn210ql-editor_css",
              children: ShareCode,
            },
            {
              name: "title",
              className: "content0-block-title jzjne54fwqm-editor_css",
              children: "Access Code Provided to the Scanner",
            },
            {
              name: "content",
              children:
                "The visitor provides the generated access code and the phone number the signed up on Qribu to the scanner. This is used to identify the visitor and make sure they gave permission to share their information to the site they are about to enter.",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjndq0dueg-editor_css",
              children: Questions,
            },
            {
              name: "title",
              className: "content0-block-title jzjne24af8c-editor_css",
              children: "Answer Required Questions",
            },
            {
              name: "content",
              children:
                "Depending on the site the visitor is about to visit, there will be extra questions. The Scanner could also ask the visitor which company/residence they are going to access if there are multiple tenants in the site. This is the last step in the site entry process.",
            },
          ],
        },
      },
      {
        name: "block~jzjn87bmyc7",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjndsyw8sf-editor_css",
              children: Exit,
            },
            {
              name: "title",
              className: "content0-block-title jzjndw5oerk-editor_css",
              children: "Provide a New Code on Exit",
            },
            {
              name: "content",
              children:
                "Upon exit, the visitor will have to generate another code and provide it to the scanner. The scanner will check their identity and mark their exit.",
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 8,
        className: "block",
        title: {
          className: "title",
          children: "Qribu",
        },
        childWrapper: {
          className: "slogan",
          children: [
            {
              name: "content0",
              children: <p>Your #1 choice for logging visitor entries.</p>,
            },
          ],
        },
      },
      {
        name: "block2",
        xs: 24,
        md: 8,
        className: "block",
        title: { children: <p>Contact Us</p> },
        childWrapper: {
          children: [
            {
              href: "#",
              name: "link0",
              children: <p>Nairobi, Kenya </p>,
              className: "jzl0u1bko6-editor_css",
            },
            { href: "#", name: "link1", children: "+254 799 313 786" },
          ],
        },
      },
      {
        name: "block3",
        xs: 24,
        md: 8,
        className: "block",
        title: { children: "Our Sites" },
        childWrapper: {
          children: [
            {
              href: "http://qribu.netlify.app",
              name: "link0",
              children: "Qribu",
            },
            {
              href: "http://qribu-site.netlify.app",
              name: "link1",
              children: "Qribu Sites' Portal",
            },
            {
              href: "http://qribu-company.netlify.app",
              name: "link3",
              children: "Qribu Companies' Portal",
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <div>
        <p>
          Copyright © 2020 <a href="http://qribu.netlify.app">Qribu</a>
        </p>
        <br />
      </div>
    ),
  },
};
