import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Drawer,
  Input,
  List,
  message,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";

import "../css/user-site-visits.css";
import Modal from "antd/lib/modal/Modal";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import SiteScannerDetail from "./SiteScannerDetail";
import { getDrawerPlacement } from "../functions/ResponsiveVariables";
import CompanyDetail from "./CompanyDetail";
import UserSiteUnregisteredVisitsPage from "./UserSiteUnregisteredVisitsPage";

const { TabPane } = Tabs;

export default function UserSiteVisitsPage() {
  const { siteId } = useParams();

  const [initLoading, setInitLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [visitSession, setVisitSession] = useState({});
  const [list, setList] = useState([]);
  const [startScannerUsername, setStartScannerUsername] = useState("");
  const [endScannerUsername, setEndScannerUsername] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [scannerDetailModalVisible, setScannerDetailModalVisible] = useState(
    false
  );
  const [companyDetailModalVisible, setCompanyDetailModalVisible] = useState(
    false
  );
  const [scannerInfo, setScannerInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const showCompanyDetails = (company) => {
    setCompanyInfo(company);
    setCompanyDetailModalVisible(true);
  };

  const getScannerName = (id, setter) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${siteId}/scanners?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setter(res.username);
      })
      .catch((e) => {
        return e.message;
      });
  };

  const showScannerDetails = (scannerUsername) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${siteId}/scanners?username=${scannerUsername}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        fetch(`${process.env.REACT_APP_API_URL}/users/${res.user_id}`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        })
          .then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }
            return res.json();
          })
          .then((res) => {
            setScannerDetailModalVisible(true);
            setScannerInfo(res);
          })
          .catch((e) => {
            setScannerInfo({});
            setScannerDetailModalVisible(false);
            message.error(e.message);
          });
      })
      .catch((e) => {
        return e.message;
      });
  };

  const onClose = () => {
    setVisitSession({});
    setDrawerVisible(false);
  };

  const hideScannerDetailModal = () => {
    setScannerDetailModalVisible(false);
  };
  const hideCompanyDetailModal = () => {
    setCompanyDetailModalVisible(false);
  };

  useEffect(() => {
    const id = Cookies.get("user_id");
    const getData = () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/${id}/sites/${siteId}/visits`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error("");
        })
        .then((res) => {
          if (res != null) {
            let modifiedRes = res.map((session) => ({
              ...session,
              key: session.id,
            }));
            setInitLoading(false);
            setList(modifiedRes);
          } else {
            setInitLoading(false);
          }
        })
        .catch((e) => {
          message.error(e.message);
          setInitLoading(false);
        });
    };
    getData();
  }, [siteId]);

  let searchInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : dataIndex === "firstName"
        ? record["user"]["name"] !== undefined
          ? record["user"]["name"]["first"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "lastName"
        ? record["user"]["name"] !== undefined
          ? record["user"]["name"]["last"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "middleName"
        ? record["user"]["name"] !== undefined
          ? record["user"]["name"]["middle"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : text !== undefined && text !== "" && text !== null ? (
        text
      ) : (
        "-"
      ),
  });

  const columns = [
    {
      title: "First Name",
      dataIndex: ["user", "name", "first"],
      key: "firstName",
      width: "20%",
      sorter: (a, b) => {
        if (a.user.name !== undefined) {
          if (b.user.name !== undefined) {
            return a.user.name.first.localeCompare(b.user.name.first);
          }
          return true;
        }
        return b.user.name !== undefined;
      },
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Middle Name",
      dataIndex: ["user", "name", "middle"],
      key: "middleName",
      width: "20%",
      sorter: (a, b) => {
        if (a.user.name !== undefined) {
          if (b.user.name !== undefined) {
            return a.user.name.middle.localeCompare(b.user.name.middle);
          }
          return true;
        }
        return b.user.name !== undefined;
      },
      ...getColumnSearchProps("middleName"),

      responsive: ["lg"],
    },
    {
      title: "Last Name",
      dataIndex: ["user", "name", "last"],
      key: "lastName",
      width: "20%",
      sorter: (a, b) => {
        if (a.user.name !== undefined) {
          if (b.user.name !== undefined) {
            return a.user.name.last.localeCompare(b.user.name.last);
          }
          return true;
        }
        return b.user.name !== undefined;
      },
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Entered At",
      dataIndex: "start_time",
      key: "start_time",
      sorter: (a, b) => {
        return new Date(a.start_time) > new Date(b.start_time);
      },
      width: "20%",
      ...getColumnSearchProps("start_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Exited At",
      dataIndex: "end_time",
      key: "end_time",
      width: "20%",
      sorter: (a, b) => {
        return new Date(a.end_time) > new Date(b.end_time);
      },
      ...getColumnSearchProps("end_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id) => {
        return (
          <Button
            type="link"
            onClick={() => {
              let item = list.find((item) => item.id === id);
              if (item) {
                setDrawerVisible(true);
                setVisitSession(item);
                setUserLoading(true);
                getScannerName(item.start_scanner_id, setStartScannerUsername);
                if (
                  item.end_scanner_id !== "system" &&
                  item.end_scanner_id !== undefined
                ) {
                  getScannerName(item.end_scanner_id, setEndScannerUsername);
                } else if (item.end_scanner_id !== undefined) {
                  setEndScannerUsername("System");
                } else {
                  setEndScannerUsername("Still in the premises");
                }
                setUserLoading(false);
              }
            }}
          >
            <InfoCircleOutlined />
          </Button>
        );
      },
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Registered Users" key="1">
        <>
          <Spin spinning={initLoading} className="visits-container">
            <Table
              columns={columns}
              dataSource={list}
              style={{ overflowX: "scroll", margin: "0" }}
            />
          </Spin>
          <Drawer
            placement={getDrawerPlacement()}
            height="75vh"
            width="35%"
            closable={true}
            onClose={onClose}
            visible={drawerVisible}
          >
            <Card
              title={
                visitSession.user
                  ? `${visitSession.user.name.first} ${
                      visitSession.user.name.middle != null
                        ? visitSession.user.name.middle + " "
                        : ""
                    }${visitSession.user.name.last}`
                  : ""
              }
              bordered={false}
            >
              <Skeleton loading={userLoading} paragraph>
                <p>{`Phone Number: ${
                  visitSession.user !== undefined
                    ? visitSession.user.phone_number
                    : "-"
                }`}</p>
                <p>{`ID Number: ${
                  visitSession.user !== undefined
                    ? visitSession.user.id_number
                    : "-"
                }`}</p>
                <p>{`Email: ${
                  visitSession.user !== undefined &&
                  visitSession.user.email !== undefined
                    ? visitSession.user.email
                    : "-"
                }`}</p>
                <p>{`Company: ${
                  visitSession.user !== undefined &&
                  visitSession.user.company !== undefined
                    ? visitSession.user.company
                    : "-"
                }`}</p>
                <p>{`Car License Plate: ${
                  visitSession.user !== undefined &&
                  visitSession.user.car_license_plate !== undefined
                    ? visitSession.user.car_license_plate
                    : "-"
                }`}</p>
                <p>{`Date of Birth: ${
                  visitSession.user !== undefined
                    ? new Date(visitSession.user.dob).toLocaleDateString()
                    : "-"
                }`}</p>

                <p>
                  Entrance Scanner:{" "}
                  <Button
                    type="link"
                    size="small"
                    style={{ padding: 0, margin: 0 }}
                    onClick={() => {
                      showScannerDetails(startScannerUsername);
                    }}
                  >
                    {startScannerUsername}
                  </Button>
                </p>
                <p>
                  Company Visited:{" "}
                  <Button
                    type="link"
                    onClick={() => {
                      if (visitSession.company !== undefined) {
                        showCompanyDetails(visitSession.company);
                      }
                    }}
                  >
                    {` ${
                      visitSession.company !== undefined
                        ? visitSession.company.name
                        : "-"
                    }`}
                  </Button>
                </p>
                <p>{`Entrance Time: ${
                  visitSession.start_time !== undefined
                    ? new Date(visitSession.start_time).toLocaleString()
                    : "-"
                }`}</p>

                <p>Entrance Scanner's Note: {visitSession.start_note ?? "-"}</p>
                <p>{`Entrance Time: ${
                  visitSession.start_time !== undefined
                    ? new Date(visitSession.start_time).toLocaleString()
                    : "-"
                }`}</p>
                <p>Flagged: {visitSession.flagged ? "Yes" : "No"}</p>

                {visitSession.extras !== null ? (
                  <List
                    dataSource={visitSession.extras}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.name}
                          description={item.value}
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <div />
                )}
                <p>
                  Exit Scanner:{" "}
                  <Button
                    type="link"
                    size="small"
                    style={{ padding: 0, margin: 0 }}
                    onClick={() => {
                      if (
                        endScannerUsername !== "System" &&
                        !String(endScannerUsername).includes(" ")
                      )
                        showScannerDetails(endScannerUsername);
                    }}
                  >
                    {endScannerUsername}
                  </Button>
                </p>
                <p>Exit Scanner's Note: {visitSession.end_note ?? "-"}</p>
                <p>{`Exit Time: ${
                  visitSession.end_time !== undefined
                    ? new Date(visitSession.end_time).toLocaleString()
                    : "-"
                }`}</p>
                <p style={{ textAlign: "center" }} />
              </Skeleton>
            </Card>
          </Drawer>
          <Modal
            visible={scannerDetailModalVisible}
            footer={null}
            onCancel={hideScannerDetailModal}
          >
            <SiteScannerDetail
              footer={null}
              fullName={
                scannerInfo.name
                  ? `${scannerInfo.name.first} ${scannerInfo.name.middle} ${scannerInfo.name.last}`
                  : "-"
              }
              phone={scannerInfo.phone_number ? scannerInfo.phone_number : "-"}
              email={scannerInfo.email ? scannerInfo.email : "-"}
            />
          </Modal>
          <Modal
            visible={companyDetailModalVisible}
            footer={null}
            onCancel={hideCompanyDetailModal}
          >
            <CompanyDetail
              Name={companyInfo.name}
              Description={companyInfo.description}
              AdminUsername={companyInfo.admin_username}
              PhoneNumber={
                companyInfo.phone_numbers !== undefined
                  ? companyInfo.phone_numbers[0]
                  : "-"
              }
              Email={companyInfo.email}
              OpeningTime={new Date(companyInfo.opening_time) || "-"}
              ClosingTime={new Date(companyInfo.closing_time) || "-"}
            />
          </Modal>
        </>
      </TabPane>
      <TabPane key="2" tab="Unregistered Users">
        <UserSiteUnregisteredVisitsPage />
      </TabPane>
    </Tabs>
  );
}
