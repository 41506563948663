import React, { useState } from "react";
import { Button, Card, Form, Input, message, Tooltip } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import PhoneFormatter from "../functions/PhoneFormatter";
import PhoneValidator from "../functions/PhoneValidator";
import { prefixSelector } from "./PhoneNumberInput";

export default function UserRegistrationForm() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/visitor/" } };
  const onFinish = (values) => {
    setIsLoading(true);
    const url = `  ${process.env.REACT_APP_API_URL}/users`;
    const data = {
      name: {
        first: values.fname,
        middle: values.mname,
        last: values.lname,
      },
      phone_number: `${values.prefix}${values.phone}`,
      email: values.email,
      id_number: values.id_number,
      password: values.password,
      company: values.company,
      dob: values.dob,
      car_license_plate: values.clp,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        Cookies.set("user_id", res.id, { expires: 365 });
        Cookies.set("token", res.token, { expires: 365 });
        Cookies.set("as", "user", { expires: 365 });
        setLoggedIn(true);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  // const AutoCompleteOption = AutoComplete.Option;

  if (isLoggedIn) {
    history.push(from);
  }
  return (
    <Card
      className="fancy-scroll register-card"
      style={{ textAlign: "center", overflowY: "scroll" }}
    >
      <Form name="register-user" onFinish={onFinish} scrollToFirstError>
        <Tooltip title="Enter your first name as it appears on your ID card.">
          <Form.Item
            name="fname"
            rules={[
              {
                required: true,
                message: "Please enter your first name.",
              },
            ]}
          >
            <Input placeholder="First name" />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Enter your last name as it appears on your ID card.">
          <Form.Item
            name="lname"
            rules={[
              {
                required: true,
                message: "Please enter your last name.",
              },
            ]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Enter a phone number you can later use to log in.">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input a phone number.",
              },
              PhoneValidator(),
            ]}
          >
            <Input
              addonBefore={prefixSelector}
              style={{ width: "100%" }}
              placeholder="Phone number"
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Enter your government given ID Number.">
          <Form.Item
            name="id_number"
            rules={[
              {
                required: true,
                message: "Please input ID Number.",
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="ID Number" />
          </Form.Item>
        </Tooltip>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input a password.",
            },
            {
              min: 8,
              message: "Your password should be at least 8 characters long.",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password.",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match."
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  name="agreement"*/}
        {/*  valuePropName="checked"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      validator: (_, value) =>*/}
        {/*        value*/}
        {/*          ? Promise.resolve()*/}
        {/*          : Promise.reject("You should accept the agreement."),*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Checkbox>*/}
        {/*    I have read and accepted the <a href="#agreement">agreement</a>*/}
        {/*  </Checkbox>*/}
        {/*</Form.Item>*/}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            shape="round"
            loading={isLoading}
          >
            Register
          </Button>
        </Form.Item>

        <Link to="/visitor/login">Already have an account?</Link>
      </Form>
    </Card>
  );
}
