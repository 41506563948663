import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Landing from "./Home";
import UserAuth from "./components/UserAuth";
import UserHomepage from "./components/UserHomePage";
import PrivateVisitorRoute from "./PrivateVisitorRoute";
import PublicRoute from "./PublicRoute";
import "./App.css";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        {/* Visitor auth */}
        <PublicRoute
          path="/visitor/login"
          exact
          render={(props) => <UserAuth {...props} />}
        />
        <PublicRoute
          path="/visitor/register"
          exact
          render={(props) => <UserAuth {...props} />}
        />
        <PublicRoute
          path="/visitor/"
          exact
          render={(props) => <UserAuth {...props} />}
        />
        {/* Visitor operations */}
        <PrivateVisitorRoute
          path="/visitor/passes"
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/key"
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/sites/:siteId"
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/sites/:siteId"
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/history"
          exact
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/edit"
          exact
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/edit/password"
          exact
          render={(props) => <UserHomepage {...props} />}
        />
        <PrivateVisitorRoute
          path="/visitor/"
          render={() => <Redirect to="/visitor/key" />}
        />
        <Route path="*" component="404" />
      </Switch>
    </Router>
  );
}
