import React from "react";
import { Form, Select, Input } from "antd";

const { Option } = Select;
export const prefixSelector = (
  <Form.Item name="prefix" initialValue="+254" noStyle>
    <Select style={{ width: 90 }}>
      <Option value="+254">+254</Option>
      <Option value="+251">+251</Option>
    </Select>
  </Form.Item>
);
