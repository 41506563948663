import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Button, Card, Form, Input, message } from "antd";
import Cookies from "js-cookie";

export default function UserCodeModal({ history }) {
  const [codeLoading, setCodeLoading] = useState(false);

  function hideCodeModal() {
    history.push("/visitor/passes");
  }

  function loadSiteFromShortCode(values) {
    const user_id = Cookies.get("user_id");

    if (values != null) {
      setCodeLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/passes/users/${user_id}/${values.site_code}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            setCodeLoading(false);
            return response.json();
          }
          setCodeLoading(false);
          throw new Error("Site not found");
        })
        .then(() => {
          hideCodeModal();
        })
        .catch((e) => {
          message.error(e.message);
          setCodeLoading(false);
        });
    }
  }

  return (
    <Modal
      visible
      closable
      onCancel={hideCodeModal}
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
      footer={null}
    >
      <Card bordered={false}>
        <p>Enter the unique code of the site you're about to visit</p>
        <Form name="site_search" onFinish={loadSiteFromShortCode}>
          <Form.Item
            name="site_code"
            rules={[
              { required: true, message: "You should enter the site code" },
              {
                min: 6,
                message: "Site code cannot be shorter than 6 characters",
              },
            ]}
          >
            <Input placeholder="Site Code" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={codeLoading}>
              Search
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  );
}
