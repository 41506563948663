import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { Route, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";

import "../css/user-dashboard.css";

import Title from "antd/lib/typography/Title";
import QRCode from "qrcode.react";
import UserQrModal from "./UserQrModal";
import UserCodeModal from "./UserCodeModal";

const history = createBrowserHistory();

function UserDashboard() {
  // const [result, setResult] = useState("no result");
  // const [codeLoading, setCodeLoading] = useState(false);
  // const [codeLoadingError, setCodeLoadingError] = useState("");

  const [isLoading, setIsloading] = useState(false);
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [qrHidden, setQrHidden] = useState(true);

  const getCode = () => {
    setIsloading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/users/${Cookies.get(
        "user_id"
      )}/generate-key`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        setIsloading(false);
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setCode(res.access_key);
        setPhone(res.phone);
        setQrHidden(false);
      })
      .catch((e) => {
        message.error(e.message);
        setCode("Error!");
        setQrHidden(true);
      });
  };

  useEffect(() => {
    getCode();
  }, []);

  return (
    <>
      <div className="container">
        <p>
          {/* <Link to="/visitor/scan">
              <Button
                style={{ margin: 2 }}
                type="primary"
              >
                Scan QR
              </Button>
            </Link>
            <Link to="/visitor/entercode">
              <Button
                style={{ margin: 2 }}
                type="primary"
              >
                Enter Code
              </Button>
            </Link> */}
        </p>

        <QRCode
          value={JSON.stringify({
            phone,
            access_key: code,
          })}
          size={175}
          bgColor="#ffffff"
          fgColor="#000000"
          level="L"
          includeMargin={false}
          renderAs="svg"
          hidden={qrHidden}
        />
        <Title level={1} style={{ fontWeight: 300, marginTop: 25 }}>
          {code}
        </Title>
        <Button
          style={{ margin: 2 }}
          type="primary"
          onClick={getCode}
          loading={isLoading}
        >
          Generate Key
        </Button>
      </div>
      <Switch>
        <Route
          path="/visitor/scan"
          history={history}
          exact
          component={UserQrModal}
        />
        <Route
          path="/visitor/entercode"
          history={history}
          exact
          component={UserCodeModal}
        />
      </Switch>
    </>
  );
}

export default withRouter(UserDashboard);
